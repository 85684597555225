.form__container {
  .form__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &--textarea {
      label {
        margin-bottom: 15px;
      }
    }

    input {
      padding: 15px 0 5px;
      border: none;
      border-bottom: 1px solid $clrBlue;
      color: $clrGreyDark;

      &:focus {
        outline: none;
      }
    }

    textarea {
      padding: 10px;
      border: 1px solid $clrBlue;
      color: $clrGreyDark;

      &:focus {
        outline: none;
      }
    }
  }

  label {
    font-weight: 900;
    color: $clrBlue;
  }

  .errors {
    width: 100%;
    background-color: $clrError;
    padding: 5px;
    text-align: center;
    color: $clrWhite;
    font-size: $fontSize12;
  }
}

.form-select {
  width: auto;
  margin-bottom: 30px;
}
