.footer {
  position: relative;
  font-size: $fontSize14;
  background-color: $clrGreyMedium;

  &--top,
  &--bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &--top {
    padding: 50px 0;
    border-bottom: 1px solid $clrGreyBorder;

    @include media-breakpoint-up(lg) {
      padding: 30px 0;
    }
  }

  &--bottom {
    padding: 50px 0;

    @include media-breakpoint-up(lg) {
      padding: 15px 0;
    }
  }
}

.footer__logo {
  width: 165px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.footer__contact {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: center;

  @include media-breakpoint-up(lg) {
    align-items: center;
    margin-bottom: 0;
  }

  &__header,
  &__item__text {
    color: $clrBlue;
    font-weight: bold;
  }

  &__item {
    display: flex;
    width: 200px;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }

    &__link {
      margin-left: 30px;
      text-decoration: underline;

      &:hover {
        color: $clrBodyFont;
      }
    }
  }
}

.footer__social {
  display: flex;
  align-items: center;

  > span {
    flex-shrink: 0;
    color: $clrBlue;
    margin-right: 10px;
  }

  &__link {
    svg {
      width: 23px;
      height: 25px;
      fill: $clrBlue;
      transition: all $duration-normal $easeOutCirc;
    }

    &:hover {
      svg {
        fill: $clrBodyFont;
        transform: scale(1.1);
      }
    }
  }
}

.footer__menu {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  > span {
    color: $clrBlue;
    display: block;
    margin: 0 0 10px 0;

    @include media-breakpoint-up(lg) {
      margin: 0 15px 0 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin-bottom: 0;
    }

    &__item {
      &__link {
        padding: 3px 0;
        display: block;
        text-decoration: underline;

        @include media-breakpoint-up(lg) {
          border-left: 1px solid $clrBlue;
          padding: 0 15px;
          text-decoration: none;
        }
      }
    }
  }
}

.footer__madeby {
  display: flex;
  align-items: center;

  > span {
    color: $clrBlue;
    margin-right: 15px;
  }

  &__logo {
    width: 25px;
  }
}
