.hero {
  height: calc(100vh - 80px);
  height: var(--hero-height);
  position: relative;
  display: flex;
  align-items: center;

  &--small {
    height: auto;
    max-height: 350px;

    @include media-breakpoint-up(xl) {
      max-height: 400px;
    }

    @include media-breakpoint-up(xxl) {
      max-height: 500px;
    }
  }
}

.hero__title {
  &__wrapper {
    position: absolute;
    width: 100%;
  }
}

.hero__img {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.35);
}

.hero__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  svg {
    width: 100px;
    height: 130px;
    fill: $clrWhite;

    @include media-breakpoint-up(md) {
      width: 130px;
      height: 160px;
    }
  }
}

.hero__arrow-down {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);

  svg {
    width: 20px;
    height: 25px;
    fill: $clrWhite;
    animation: bounce 2s infinite;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
