.quote__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote__image {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 30px;
}

.quote__text {
  text-align: center;
  font-style: italic;
  color: $clrGreyDark;
  position: relative;

  svg {
    width: 130px;
    position: absolute;
    left: 0;
    top: -70px;
    fill: $clrBlue;
    z-index: -1;
    opacity: 0.2;

    @include media-breakpoint-up(md) {
      width: 200px;
      left: -80px;
      top: -100px;
    }
  }
}

.quote__person {
  text-align: center;
  color: $clrGreyDark;
  font-weight: 900;
  margin-bottom: 30px;
}
