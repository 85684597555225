.navigation {
  height: 100vh;
  height: var(--app-height);
  width: 100%;
  z-index: 2;
  padding-top: 80px;
  background-color: $clrWhite;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
  position: fixed;
  transition: transform $duration-slow $easeOutCirc;

  &.js-open {
    transform: translateY(0);
  }
}

.navigation__list {
  margin-bottom: 50px;

  &__item {
    display: flex;
    justify-content: center;

    &__link {
      text-align: center;
      font-size: $fontSize32;
      font-weight: 900;
      text-transform: uppercase;
      width: 100%;

      &:hover {
        text-decoration: none;
        color: $clrBodyFont;
      }
    }
  }
}
