.text-image {
  &--left {
    .text-image__img {
      margin-bottom: 45px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  &--right {
    .text-image__text {
      margin-bottom: 45px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  &__img {
    width: 100%;

    img {
      width: 100%;
    }
  }
}
