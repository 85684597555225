.columns {
  &__column {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}
