@import "./node_modules/glightbox/dist/css/glightbox.min";

.gallery {
  .gallery__item {
    display: inline-block;
    vertical-align: top;
    position: relative;

    img {
      width: 100%;
      vertical-align: middle;
    }
  }

  &--masonry {
    .gutter__sizer {
      width: 2%;
    }

    .gallery__sizer,
    .gallery__item {
      width: 48%;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }

      @include media-breakpoint-up(md) {
        width: 32%;
      }
    }

    .gallery__item {
      img {
        width: 100%;
        vertical-align: middle;
      }
    }
  }

  &__image {
    width: 100%;
  }
}
