.header {
  z-index: 500;
  width: 100%;
  background-color: $clrWhite;
  position: fixed;
  border-bottom: 1px solid $clrGreyBorder;

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }
}

.header__logo {
  svg {
    width: 160px;
    height: 40px;

    @include media-breakpoint-up(md) {
      width: 185px;
      height: 45px;
    }
  }
}

.header__menu {
  cursor: pointer;
  display: flex;
  align-items: center;

  &.js-active {
    .text {
      .close {
        display: block;
      }

      .open {
        display: none;
      }
    }
  }

  .text {
    margin-right: 15px;
    text-transform: uppercase;
    color: $clrBlue;
    font-weight: 700;
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    .close {
      display: none;
    }

    .open {
      display: block;
    }
  }
}
