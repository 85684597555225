.section-mb {
  padding-bottom: 80px;

  @include media-breakpoint-up(md) {
    padding-bottom: 120px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 150px;
  }
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-120 {
  padding-bottom: 80px;

  @include media-breakpoint-up(md) {
    padding-bottom: 100px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }
}

.pt-120 {
  padding-top: 80px;

  @include media-breakpoint-up(md) {
    padding-top: 100px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 120px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-0 {
  margin-bottom: 0;
}


.clr {
  &--blue {
    color: $clrBlue;
  }

  &--white {
    color: $clrWhite;
  }

  &--grey-dark {
    color: $clrGreyDark;
  }
}

.txt-line-height {
  &--big {
    line-height: 28px;
  }

  &--medium {
    line-height: 22px;
  }

  &--small {
    line-height: 18px;
  }
}

.font {
  &--black {
    font-weight: 900;
  }
}
