.hamburger {
  width: 30px;
  cursor: pointer;

  .js-active & {
    &:before {
      transform: translateY(9px) rotate(135deg);
    }

    &:after {
      transform: translateY(-9px) rotate(-135deg);
    }

    div {
      transform: scale(0);
    }
  }

  &:before,
  &:after,
  div {
    background: $clrBlue;
    content: "";
    display: block;
    height: 2px;
    border-radius: 3px;
    margin: 7px 0;
    transition: 0.5s;
  }
}
