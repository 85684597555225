.usp {
  background-color: $clrBlue;

  &__title {
    text-align: center;
  }
}

.usp__item {
  color: $clrWhite;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__img {
    height: 80px;
    margin-bottom: 15px;

    img {
      object-fit: contain;
    }
  }

  &__title {
    font-size: $fontSize20 !important;
  }
}
