.projects__item {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  &:hover {
    .projects__item {
      &__img {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &__content {
    width: 100%;
    margin-bottom: 30px;
  }

  &__img {
    overflow: hidden;
    margin-bottom: 15px;

    img {
      width: 100%;
      transition: transform $duration-normal $easeOutCirc;
    }
  }

  .link {
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}

.projects-list {
  .projects__item {
    @include media-breakpoint-up(lg) {
      padding-bottom: 60px;
    }
  }
}
