$clrVideoPlayBtn: $white;

.button,
.wysiwyg .button {
  display: inline-block;
  border: none;
  color: white;
  text-decoration: none;
  background-color: $clrBlue;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 20px;
  transition: transform $duration-normal $easeOutCirc;

  &:hover {
    transform: scale(1.05);
    color: $clrWhite;
    text-decoration: none;
  }

  &--white {
    background-color: $clrWhite;
    color: $clrBlue;

    &:hover {
      color: $clrBlue;
    }
  }
}

.link {
  &--read-more {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;

      .icon {
        transform: translateX(5px);
      }
    }

    .text {
      flex-shrink: 0;
      margin-right: 10px;
      font-weight: 700;
    }

    .icon {
      width: 12px;
      height: 11px;
      fill: $clrBlue;
      transition: transform $duration-normal $easeOutCirc;
    }
  }
}
